<template>
  <div class="wrapper">
      <sidebar />
      <main class="main">
        <headerComponent />
        <div class="pages">
          <router-view/>
        </div>
      </main>
  </div>
</template>

<script>
// import Sidebar from '@/components/Sidebar';
// import Header from '@/components/Header';
export default {
  name: 'default'
}
</script>

<style>

</style>